export default {
    notoken: '登录失效，请重新登录',
    tologin: '请先登录',
    qita: {
        rd: '确定',
        qx: '取消',
        tc: "确认是否退出登录",
        tc2: '退出登录',
        grxx: '我的信息',
        tx1: '头像',
        name: '姓名',
        em: '邮箱',
        ph: '手机号',
        tzc: '碳资产持有量',
        ckjl: '查看记录',
        cyl: '持有量',
        xmid: '项目ID',
        xmidplpl: '请填写项目ID',
        tons: '吨',
        rgm: '人已购买此项目',
        gjdq: '国家地区',
        cyz: '持有者',
        nf: '年份',
        gm: '购买',
        gmtzc: '购买碳资产',
        chlb: '自由交易碳资产列表',
        zanwu: '暂无数据',
        czgl: '操作管理',
        cstzc: '出售碳资产',
        sq: '申请',
        zzsq: '正在申请中',
        jyjl: '交易记录',
        xq: '详情',
        xmbh: '项目编号',
        xmlb: '项目类别',
        sl: '数量',
        kssj: '计入期开始时间',
        jssj: '计入期结束时间',
        cs: '出售',
        csjg: '出售价格',
        tps: "提示:出售价格区间在",
        cssl: '出售数量',
        zsjl: '在售记录',
        xjjl: '下架记录',
        gmjl: '购买记录',
        xj: '下架',
        qrxj: '确认是否下架?',
        ts: '提示',
        jg: '价格',
        gmsl: '购买数量',
        gmrq: '购买日期',
        fh: '返回',
        tzchx: '碳资产抵销',
        tzcsd: '正在申请托管中',
        tzcsd2: '正在申请信托中',
        hxs: '抵销',
        qynm: '请输入抵销人姓名或企业名称',
        sryt: '请输入抵销用途',
        jxt: '碳资产抵销记录',
        hxsl: '抵销数量',
        cyrq: '持有日期',
        hxsj: '抵销时间',
        hxzt: '抵销状态',
        dsh: '待审核',
        ywc: '审核成功',
        wtg: '审核未通过',

        zztzc: '碳资产转赠',
        zzs: '转赠',
        dfsjh: '请输入对方手机号',
        zzjls: '碳资产转赠记录',
        zzlx: '转赠类型',
        wzzg: '我转赠给',
        zzgw: '转赠给我',
        zc1: '转出',
        zr: '转入',
        zzrq: '转赠日期',

        wdqb: '我的钱包',
        ye: '余额',
        yemx: '余额明细',
        jyjl: '交易记录',
        tx: '提现',
        txje: '提现金额',
        txtips: '提现的服务费为',
        txtips2: '申请提现后,我们将在三个工作日内联系您',
        jydh: '交易单号',
        jylx: '交易类型',
        cz2: '充值',
        czje: '充值金额',
        sxf: '手续费',
        txzt: '状态',
        txsj: '时间',
        txje2: '金额',
        ly: '来源',
        tzcgljl: '碳资产管理记录',
        tg1: '托管量',
        tg2: '托管',
        xt1: '信托量',
        xt2: '信托',
        sqrq: '申请日期',
        sqttg: '申请碳托管',
        sqtxt: '申请碳信托',
        sqtips: '申请后,我们将在2个工作日内与您取得联系,确定要申请吗?',
        lxwm: '联系我们',
        wtlx: '问题类型',
        xzwt: '请选择问题类型',
        mswt: '请描述出现的问题',
        lxfs: '请填写联系方式',
        gnyc: '功能异常',
        cpjy: '产品建议',
        qtwt: '其他问题',

        gh: '更换',
        xg: '修改',
        bcss: '保存',
        xgmm: '修改密码',
        xgmmtips: '请输入您需要更换的新手机号',
        xgsjh: '更换手机号',
        tips: '申请信托其他碳资产',
        tips2: '申请托管其他碳资产',
        djzl: '点击这里',

        sqrxm: '申请人姓名',
        sqrxm2: '多人用逗号隔开',
        sqrxm3: '请填写申请人姓名',
        sqrs: '申请人数',
        gmxm: '购买项目',

        qxzgmxm: '请选择购买的项目',
        dizhis: '地址',
        dizhis2: '用于邮寄证书',
        dizhis3: '请填写地址',
        g: '共',
        d: '吨',
        hj: '合计',
        bstk: '不设退款',
        tq: '提取',
        txtzh: '请填写您的碳账户',

        tzxsl: "碳信用数量",
        yxztxysl: '请选择碳信用数量',
        shpxs: '时间排序',
        ss1: '升序',
        ss2: '降序',
        jgqj: '价格区间',
        zdj: '最低价',
        zgj: '最高价',
        chongz: '重置',
        shaixuans: '筛选',
        zzcaoz: '终止',

        buys: "购买",
        hzzy: "核证自愿减排量",
        zcdl: '注册/登录',
        zcdlsu: '注册成功',
        zcdlcode: '验证码发送成功',
        ksjy: "马上行动",

        xw: "气候资讯",
        gdss: "更多",
        chsq: '撤回申请',
        qrchsq: '确定要撤回申请',
        cz: '操作',
        sl: "数量",
        zc: '资产',
        sj: '时间',
        jlmx: '碳资产记录明细',
        jl: '记录',

        hxhb: "抵销海报",

        pm: '排名',
        tx1: '头像',
        yhm: '用户名',
        thxl: '碳抵销量',
        gnkf: '功能正在开发中，敬请期待',
        fhsy: '返回首页',

        pfanngfs: '碳排放量',
        dxiaofs: '抵销方式',
        zchbxms: '支持环保项目',
        shiyyue: '使用账户碳信用余额',
        syengyus: '剩余',
        xmchecks: '项目选择',
        xmdanjias: '项目单价',
        zfjine: '支付金额',
        quxioaos: '取消',
        quedings: '确定',
        zanwuchisyoas: '暂无持有碳资产项目',
        chiyoushuliangs: '持有数量',
        dixiaohou: "抵销后剩余量",
        hexiaoyhut: '抵销用途',
        hexiaoyhutss: '请填写抵销用途',
        cyslbz: '持有数量不足',

    },

    headtitle: {
        shouye: '首页',
        carbonguanli: '碳管理',
        carjiaoyi: '碳交易',
        carkuaiji: '碳会计',
        my: '我的主页',
        jtjy: '零碳家园',
        jc: '碳资产寄存',
        tg: '碳资产托管',
        jr: '碳资产信托',

        grxx: '我的信息',
        wdtzh: '我的账户',
        yjfk: '联系我们',
        ota: 'OTC销售',
        ch: '碳交易撮合',

        ltgm: '零碳公民',
        ltqy: '零碳企业',
        lthy: '零碳会议'


    },
    building: '敬请期待',
    lg: 'CH',
    btn: {
        shenq: '申请',
        login: '登录',
        zhuce: '注册账号',
        wjpwd: '忘记密码',
        zhuce: '注册'
    },
    ipt: {
        area: '区号',
        phone: '请填写手机号',
        pwd: '请填写密码',
        xzarea: '请选择区号',
        qymc: '请填写企业名称',
        xmfzr: '请填写项目负责人姓名',
        lxyx: '请填写邮箱',
        yx2: "邮箱格式不正确",
        rzjg: '碳资产认证机构',
        xmbh: '请填写项目编号',
        xmmcname: '项目名称',
        xmmc: '请填写项目名称',
        xmlb: '请选择项目类别',
        txynf: '碳信用年份',
        qxztxynf: "请选择碳信用年份",
        xzrq: '选择日期',
        xmxlh: '请填写碳信用序列号',
        txsld: '请填写数量(吨)',
        xmjrrq: '项目计入日期',
        xzjrrq: '请选择项目计入日期',
        kssr: '开始日期',
        jssr: '结束日期',



        geren: '个人注册',
        qiye: '企业注册',
        txxm: '请填写姓名',
        codes: '请填写验证码',
        getyzm: '获取验证码',
        txem: '请填写邮箱',
        mm: '请填写密码',
        mm2: '请再次输入密码',
        mm3: '两次输入密码不一致',
        qxz: '请选择',
        xzxmlb: '请选择项目类别',
        rygm: '请选择人员规模',



    },
    buy: {
        gmds: '购买吨数',
        qsy: '请使用',
        zf: '支付',
        zh: '收款账户',
        je: '付款金额',
        tips: '提示:支付完成后，请上传付款截图，我们将为您审核',
        fkjt: "付款截图",
        dj: '点击选择图片',
        copy: '复制成功',
        qsc: '请上传截图',
        djfz: '点击复制',
        scsb: '图片上传失败,请重试',
        cyl: '持有量',
        xmid: '项目ID',
        gmrs: '人已购买该项目',
        gjdq: '国家地区',
        cyz: '持有者',
        xmnf: '项目年份',
        sy: '剩余',
        buy: '购买',
        sub: '提交',

        cyz: '持有者',
        xmbh: '项目编号',
        xmlb: '项目类别',
        nf: '年份',
        sl: '数量',
        jrq: '计入期开始时间',
        jsq: '计入期结束时间',
        tpxz: '上传图片只能是 jpg、jpeg、png或gif 格式!!!',



        gmnf: '购买年份',
        gmsl: '购买数量',


        zfcg: '支付成功',
        cgtips: '碳资产项目预计将在十分钟内到账,届时会有短信通知您,请您耐心等待',
        zfsb: '支付失败',
        sbtips: `
        <div>请检查以下问题</div>
        <div>1.您取消了PayPal支付</div>
        <div>2.您的PayPal余额不足，请确保余额充足</div>
        <div>3.您的付款金额超过了设置的在线付款限额</div>
        <div>4.您尚未开通网上支付功能，付款前请您先开通该功能。</div>
        `,
        wdzh: '我的碳账户',

        zfje: '支付金额',
        yezf: '余额支付',
        yebz: '余额不足',
        dqye: '当前余额为',
        zf: '支付',
        ddyc: '订单异常，请重试'


    },

    tkj: {
        tcyl: '碳持有量',
        fzlj: '复制链接',
        fzcg: '复制成功',
        fx: '分享',
        xz: '勋章',
        phb: '排行榜',
        sys: '算一算',
        jsjg: '计算结果',
        ndtpfw: '您的碳排放为',
        qx: "取消",
        hx: "抵销",
        thxl: '碳抵销量',
        cg: "超过",
        yh: '用户',
        phb: '排行榜',
        ryxz: '荣誉勋章',
        jlmx: '记录明细',
        djsm: '等级说明',
        thxphb: '碳抵销排行榜',
        gxsj: '更新时间',
        ywc: '已完成',
        wwc: '未完成',
        lx: '类型',
        sj: '时间',
        dj: '等级',
        ch: '称号',

        qt: '其他',
        y: '衣',
        s: '食',
        z: '住',
        x: '行',
        y: '用',
        xzrq: '选择日期',
        ly: '近6个月',
        yn: '近1年',
        wn: '近5年',
        kpj: '开盘价',
        zjs: '昨结算',
        zgj: '最高价',
        zdj: '最低价',
        jg: '价格',
        trsj: "请填入数据",
        xzapp: '扫码下载App'
    },
    metting: {
        dates: '选择日期',
        jbts: '请填写举办天数',
        cyrs: '请填写参与人数',
        gzrs: '请填写观众人数',
        gs: '估算',
        js2: '精算',
        xyb: '下一步',
        mswz: '描述文字',
        xzdq: '选择地区',
        zj: '碳排放量共计',
        mxrx: '明细如下',
        zbf: '请填写主办方单位',
        hymc: '请填写会议/活动名称',
        fzr: '请填写项目负责人姓名',
        zhzb: '选择中和指标',
        shuom: '说明:系统会根据您输入的内容预先生成描述模板，您也可以自行编辑描述内容',
        fkzje: '付款总金额',
        syb: '上一步',
        qued: '确定',
        fkfs: '请选择支付方式',
        fkzje: '付款总金额',
        yezf: '余额支付',
        dqye: '当前余额为',
        zf: '支付',
        qx: '取 消',
        yebz: '余额不足 请使用paypal支付',
        zfcg: '支付成功',
        txwzxx: '请填写完整的信息',
        ren: '人',
        zai: '在',
        jubanle: '举办了 为期',
        shenq: '天申请',
        tzh: '进行碳中和',
        js: '计算',
        qrxx: '确认信息',
        qtxms: '请填写描述',
    },
    upl:{
        text1:"想拥有更完整的互动体验吗?",
        text2:"下载我们的移动应用,立即享受低碳生活",
        text3:'扫描二维码下载',
        text4:'准备好低碳之旅了吗?',
        text5:"让我们一起为地球减负!",
        text6:"下载",
        carousel:[
            {
                img:require('@/assets/chupl_1.png'),
                title:'计算个人碳排放'
            },{
                img:require('@/assets/chupl_2.png'),
                title:'每天学习一个碳知识'
            },{
                img:require('@/assets/chupl_3.png'),
                title:'探索绿色主题，践行绿色理念'
            }
        ]

    },
    count:{
        qxzjd:'请选择家电',
        pflzj:'碳排放量共计',
        mxrx:'明细如下',
        syb:'上一步',
        xyb:'下一步',
        hexiao:'核销',
        js:'计算',
        dxsl:'抵销数量不能为0',
        shul:'数量',
        kssj:'开始时间',
        jtrk:"家庭人口",
        zfmj:'请选择住房面积',
        renshu:'人数',
        jbts:"请填写举办天数",
        cyrs:'请填写参与人数',
        gzrs:'请填写观众人数',
        sellsucccess:'出售成功',
        remsuccess:'抵销成功',


        school:'请填写学校名称',
        schooltype:'请选择学校类型',
        mianji:"请填写建筑面积",
        shishen:'请填写师生人数',
        xuanzetime:'请选择时间',

        qymc:"请填写企业名称",
        hyleix:'请选择行业类型',
        qiyencz:'请填写企业年产值',
        wanyuan:"万元",
        jbxxs:'基本信息'
    }

}