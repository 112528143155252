<template>
  <div class="home" v-loading="indexloading">
    <!-- 轮播 -->
    <div class="bennerbox">
      <el-carousel trigger="click" :height="bennerheight + 'px'" :interval="4000">
        <el-carousel-item v-for="(item, index) in headimglist" :key="index">
          <img class="headimg" ref="heiimg" :src="$carbon.baseurl + item.img" alt="" @load="imgload"
            @click="newslink(item.state, item.url)" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播结束 -->
    <div class="wid1280">
      <div class="mgtp2  threelunbo">
        <el-carousel arrow="never" height="50px" trigger="click" indicator-position="none">
          <el-carousel-item v-for="(item, index) in headgroup" :key="index">
            <div class="threebox ">
              <div class="ellipsis" v-for="(chilitem, chilindex) in item" :key="chilindex">
                <img :src="$carbon.baseurl + chilitem.head_profile" alt="">
                <span class="ellipsis">{{ chilitem.name_ex }} {{ $lg.qita.buys }} {{ chilitem.num }}kg {{ $lg.qita.hzzy
                }}</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="mgtp2 carbonprice" v-if="day_price.name" @click="$carbon.tolink('/carbonecharts', 2)">
        <div>
          <span class="caprname"> <img class="laba" src="@/assets/lbbbb.gif" alt=""> {{ day_price.name }}</span>
          <span class="capr_pr" :class="day_price.diff_price > 0 ? 'colo' : 'colo2'">{{ day_price.closing }}</span>
          <span class="capr_qj" :class="day_price.diff_price > 0 ? 'colo' : 'colo2'"> {{ day_price.diff_price }} ({{
            day_price.diff_rate }}%)</span>
        </div>
        <div style="margin: 0 30px;">|</div>
        <div class="car_jg">
          <span>{{ day_price.day }}</span>
          <span>{{ $lg.tkj.kpj }}:{{ day_price.opening }}</span>
          <span>{{ $lg.tkj.zjs }}:{{ day_price.yestoday_price }}</span>
          <span v-if="$lg.tkj.zgj">{{ $lg.tkj.zgj }}:{{ day_price.high }}</span>
          <span v-if="$lg.tkj.zdj">{{ $lg.tkj.zdj }}:{{ day_price.low }}</span>
        </div>
      </div>

      <div class="mgtp2 imgbox hdimgs_details bors ">
        <img @click="$carbon.tolink(introduce.link, 2)" class="bors " :src="$carbon.baseurl + introduce.img" alt="" />
        <div class="imgbtns" v-if="introduce.img">
          <!-- <div v-if="btnshow" @click="$carbon.tolink('/My', 1)">{{ $lg.headtitle.wdtzh }}</div>
          <div v-else @click="$carbon.tolink('/login', 2)">{{ $lg.qita.zcdl }}</div> -->
          <div @click="$carbon.tolink('/carboncount', 2)">{{ $lg.qita.ksjy }}</div>
        </div>
      </div>
    </div>
    <!-- 碳交易 -->
    <div class="mgtp allbox" v-if="carbon_trading.title">
      <div class="wid1280">
        <div class="boxtitlecontent">
          <div class="tit">{{ carbon_trading.title }}</div>
          <div class="cont">
            {{ carbon_trading.content }}
          </div>
        </div>
        <div class="carbon_tjy">
          <div v-for="(item, index) in carbon_trading.item" :key="index" @click="tolink2(item.link, item.out_link)"
            class=" backcolor">
            <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            <div class="carbon_tjy_content flextwos">
              <div class="fl1s_1">
                <div class="carbon_tjy_tltle">{{ item.name }}</div>
                <div class="carbon_tjy_cont ellipsis">{{ item.content }}</div>
              </div>
              <div class="fl1s_2">
                <img src="../assets/xz.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 碳交易结束 -->
    <div class="wid1280">
      <!-- 碳详情 -->

      <!-- 碳详情结束 -->
      <!-- 碳资产管理 -->
      <div class="mgtp" v-if="carbon_assets.title">
        <div class="boxtitlecontent">
          <div class="tit">{{ carbon_assets.title }}</div>
          <div class="cont">
            {{ carbon_assets.content }}
          </div>
        </div>
        <div>
          <div class="carbon_zc backcolor changebackground" v-for="(item, index) in carbon_assets.item" :key="index"
            @click="$carbon.tolink(item.link, 2)">
            <div class="carbon_zc_icon imgbox">
              <img :src="$carbon.baseurl + item.img" alt="" />
            </div>
            <div class="carbon_zc_content">
              <div class="carbon_zc_content_tit ellipsis">{{ item.name }}</div>
              <div class="carbon_zc_content_co ellipsisTwo">
                {{ item.content }}
              </div>
            </div>
            <div class="carbon_zc_ic">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 碳资产管理结束 -->
    </div>

    <div class="wid1280">
      <!-- 碳会计开始 -->
      <div class="mgtp" v-if="carbon_accounting.title">
        <div class="boxtitlecontent">
          <div class="tit"> {{carbon_accounting.title}} </div>
          <div class="cont">
            {{carbon_accounting.content}}
          </div>
        </div>
        <div class="carbon_kj">
          <div
            v-for="(item,index) in carbon_accounting.item"
            :key="index"
            class="changebackground changeborder">
            <div class="carbon_kj_icon imgbox">
              <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            </div>
            <div class="carbon_kj_content">
              <div class="carbon_kj_content_tit ellipsis">{{item.name}}</div>
              <div class="carbon_kj_content_co ellipsisTwo">
                {{item.content}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 碳会计结束 -->

      <!-- 零碳家园开始 -->
      <div class="mgtp">
        <div class="boxtitlecontent">
          <div class="tit">{{ zero_carbon_home.name }}</div>
          <div class="cont">{{ zero_carbon_home.content }}</div>
        </div>
        <div class="carbon_tjy">
          <div @click="tolink2(item.link, item.out_link)" v-for="(item, index) in zero_carbon_home.item" :key="index"
            class=" backcolor">
            <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            <div class="carbon_tjy_content flextwos">
              <div class="fl1s_1">
                <div class="carbon_tjy_tltle ellipsis">{{ item.name }}</div>
              </div>
              <div class="fl1s_2">
                <img src="../assets/xz.png" alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- 零碳家园结束 -->
      <!-- 碳计算 -->
      <div class="mgtp imgbox cupo" style="padding-top:50px" v-if="carbon_calculation.img">
        <img @click="$carbon.tolink(carbon_calculation.link, 2)" class="bors imgtransition"
          :src="$carbon.baseurl + carbon_calculation.img" alt="" />
      </div>
      <!-- 碳计算 -->
      <!-- 新闻开始 -->
      <div class="mgtp" style="padding-top:50px" v-if="article.length">
        <div class="boxtitlecontent indextit_flex">
          <div class="tit">{{ $lg.qita.xw }}</div>
          <div class="more" @click="$carbon.tolink(`/news_more`, 2)">{{ $lg.qita.gdss }}<i
              class="el-icon-arrow-right"></i></div>
        </div>
        <div class="carbon_tjy">
          <div @click="$carbon.tolink(`/news?id=${item.aid}`, 2)" v-for="(item, index) in article" :key="index"
            class=" backcolor">
            <img style="height: 250px;" class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            <div class="newstext ellipsis">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <!-- 新闻开始结束 -->

    </div>
    <!-- <div class="ewmxz">
      <img src="@/assets/xzewm.png" alt="">
      <div class="sz">{{ $lg.tkj.xzapp }}</div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { homePage } from "@/api/index";
export default {
  components: {},
  data() {
    return {
      bennerheight: "340",
      btnshow: false,
      headimglist: [],
      headgroup: [],
      introduce: {
        img: "",
        link: "",
      },
      carbon_information: {
        img: "",
        link: "",
      },
      day_price: {
        closing: "",
        day: "",
        diff_price: '',
        diff_rate: '',
        high: "",
        low: "87.59",
        name: "",
        now_price: '',
        opening: "",
        timestamp: '',
        yestoday_price: ""
      },
      carbon_calculation: {
        img: "",
        link: "",
      },
      carbon_assets: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        item: [],
      },
      carbon_trading: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        item: [],
      },
      carbon_accounting: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        item: [],
      },
      zero_carbon_home: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        item: [],
      },
      article: [],
      indexloading: true,
    };
  },
  methods: {
    imgload() {
      this.$nextTick(() => {
        this.bennerheight = this.$refs.heiimg[0].height;
      });
    },
    tolink2(link, type) {
      if (type == 1) {
        this.$carbon.tolink(link, 2);
      } else {
        window.open(link)
        // window.location.href = link;
      }
    },
    newslink(type, link) {
      if (type == 1) {
        this.$carbon.tolink(link, 2);
      } else {
        window.open(link)
        // window.location.href = link;
      }
    },
    //分割数组 
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    }
  },
  created() {
    if (localStorage.getItem("token")) {
      this.btnshow = true
    } else {
      this.btnshow = false
    }
    // console.log(navigator.userAgent)
  },
  mounted() {

    this.indexloading = true;
    homePage().then((res) => {

      this.indexloading = false;
      this.headimglist = res.data.banner;
      if (res.data.content.introduce) this.introduce = res.data.content.introduce;
      if (res.data.content.carbon_assets) this.carbon_assets = res.data.content.carbon_assets;
      if (res.data.content.carbon_trading) this.carbon_trading = res.data.content.carbon_trading;
      if (res.data.content.carbon_accounting) this.carbon_accounting = res.data.content.carbon_accounting;
      if (res.data.content.zero_carbon_home) this.zero_carbon_home = res.data.content.zero_carbon_home;
      if (res.data.content.carbon_information) this.carbon_information = res.data.content.carbon_information;
      
      if (res.data.content.carbon_calculation) this.carbon_calculation = res.data.content.carbon_calculation;
      if (res.data.day_price) this.day_price = res.data.day_price;

      this.headgroup = this.group(res.data.userList, 3);
      this.article = res.data.article

      // console.log(this.headgroup);
    });

    this.imgload();
    window.addEventListener("resize", () => {
      this.bennerheight = this.$refs.heiimg[0].height;
      this.imgload();
    });
  },
};
</script>
<style scoped lang="scss">
.hdimgs_details {
  width: 100%;
  height: 100%;
  // overflow: hidden;

  img {
    vertical-align: top;
  }
}

.home {
  .headimg {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.bennerbox {
  width: 100%;
  min-width: 1480px;
}

.threelunbo {
  // padding: 10px;
  // background-color: #f9f9fb;
  overflow: hidden;
  border-radius: 15px;
}

.threebox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div {
    background-color: #f9f9fb;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex: 1;

    margin-right: 10px;

    padding: 10px;
    font-size: 15px;
    text-align: center;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 10px;
  }
}

.boxtitlecontent {
  width: 80%;

  .tit {
    font-size: 30px;
    color: #00a0e9;
  }

  .cont {
    color: #a0a0a0;
    margin-top: 15px;
  }
}

.carbon_zc {
  padding: 30px;
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .carbon_zc_icon {
    width: 170px;
    margin-right: 40px;
  }

  .carbon_zc_content {
    width: 990px;
  }

  .carbon_zc_ic {
    width: 80px;
    text-align: center;
    font-size: 30px !important;
  }

  .carbon_zc_content_tit {
    font-size: 19px;
  }

  .carbon_zc_content_co {
    color: #a0a0a0;
    margin-top: 10px;
  }
}

.dibu {
  margin-top: 1000px;
}

.allbox {
  padding: 50px 0;
  min-width: 1130px;
}

.carbon_tjy {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .back1 {
    background-color: white;
  }

  .back1 {
    background-color: white;
  }

  >div {
    width: 350px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 40px;
    img {
      width: 100%;

    }

    .carbon_tjy_content {
      padding: 15px;

      .carbon_tjy_tltle {
        font-size: 19px;
      }

      .carbon_tjy_cont {
        margin-top: 7px;
        color: #a0a0a0;
      }
    }
  }

  >div:last-child{
    margin-right: 0px;
  }
}

.flextwos {
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  .fl1s_1 {
    width: 250px;
    margin-right: 20px;
  }

  .fl1s_2 img {
    width: 40px;
    height: 40px;
    vertical-align: top;
  }
}

.carbon_kj {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  >div {
    width: 565px;
    padding: 20px;
    border-radius: 7px;
    border: 1px solid #ebebeb;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .carbon_kj_icon {
      width: 70px;
      margin-right: 30px;
    }

    .carbon_kj_content {
      width: 385px;

      .carbon_kj_content_tit {
        font-size: 19px;
      }

      .carbon_kj_content_co {
        color: #a0a0a0;
        margin-top: 10px;
      }
    }
  }
}

.imgbtns {
  margin-left: 40px;
  font-size: 15px;
  position: relative;
  margin-top: -70px;
  color: #225b92;

  >div {
    cursor: pointer;
    transition-duration: 0.4s;
    display: inline-block;
    padding: 10px 15px;
    background-color: white;
    border-radius: 40px;
    margin-right: 20px;

  }

  >div:hover {
    background-color: #00a0e9;
    color: white;
  }
}

.newstext {
  background-color: #00000048;
  height: 30px;
  line-height: 30px;
  ;
  margin-top: -50px;
  position: relative;
  color: white;

  padding: 0 10px;
}

.indextit_flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more {
  cursor: pointer;
  color: #9f9f9f
}

.carbonprice {
  background-color: #f9f9fb;
  padding: 20px;
  cursor: pointer;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  >div:first-child {

    // border-right: 1px solid #656565;
    padding: 0 10px;
  }

  .caprname {
    font-size: 20px;
    margin-right: 15px;
    color: #00a0e9;
  }

  .capr_pr {
    font-weight: bold;
    margin-right: 15px;
  }

  .capr_qj {
    color: #3f3f3f;
  }

  .car_jg {
    padding: 0 10px;

    >span:first-child {
      font-weight: bold;
    }

    >span {
      margin-right: 15px;
      color: #3f3f3f;
    }
  }
}

.mgtp2 {
  margin-top: 30px;
}

.colo {
  color: #67C23A !important;
}

.colo2 {
  color: red !important;
}

.laba {
  width: 20px;
  position: relative;
  top: 4px;

}

.ewmxz {
  width: 100px;
  // text-align: center;
  background-color: white;
  border: 2px solid #34c4f1;
  // color: white;
  padding: 5px;
  position: fixed;
  right: 10px;
  top: 70%;
  z-index: 9999;
  border-radius: 10px;

  .ic {
    text-align: right;
  }

  .sz {
    text-align: center;
    font-size: 13px;
  }

  img {
    // margin-left: 10px;
    width: 100px;
    height: 100px;
    // margin-bottom: 10px;
  }
}

</style>