import en from "./en.js"
import ch from "./ch.js"
import id from "./id.js"

export default {
    toggle: function(version) {
        
        version = 1

        var languagetype = localStorage.getItem('language');
        if (!languagetype) {

            if (version == 1){
                localStorage.setItem('language', 'en-us')
                languagetype = 'en-us'
            }else if (version == 3){
                localStorage.setItem('language', 'id-id')
                languagetype = 'id-id'
            }else{
                localStorage.setItem('language', 'en-us')
                languagetype = 'en-us'
            }
        }
        if (languagetype == 'en-us') {
            return en
        } else if (languagetype == 'zh-cn'){
            return ch
        } else if (languagetype == 'id-id'){
            return id
        }else{
            localStorage.setItem('language', 'en-us')
            return en
        }
    }
}