<template>
  <div class="backcolor" :style="{ backgroundColor: color }" v-if="!dibuloading">
    <div class="dibubox wid1480" :style="{ backgroundColor: color }">
      <div class="dibuflex">
        <div class="dibuflex1">
          <div class="logos">
            <img src="@/assets/logos.png" @click="$carbon.tolink('/', 2)" alt="" />
          </div>
          <div class="dibu_left_content">
            {{ introduce }}
          </div>
        </div>
        <div class="dibuflex2">
          <div v-for="(item, index) in menuList" :key="index">
            <div class="dibuflx2_title">
              {{ item.title }}
            </div>
            <div class="dibuflx2_content" v-for="(item2, index2) in item.list" :key="index2"
              @click="tolink2(item2.link, item2.out_link)">
              {{ item2.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="dibuflex_2">
        <div>
          {{ jurisdiction }}
          
          <span v-for="(item,index) in service" :key="index" class="fwxy" @click="$carbon.tolink(`/news?id=${item.aid}`, 2)">{{ item.title}}</span>
          
        </div>
        <div class="linkflex">
          <div v-for="(item, index) in linkimg" :key="index" @click="tolink(item.link)">
            <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { homeFooter, serverArticle } from "@/api/index";
export default {
  data() {
    return {
      introduce: "",
      jurisdiction: "",
      menuList: [],
      linkimg: [],
      dibuloading: false,
      service: []
    };
  },
  props: {
    color: {
      type: String,
      default: "#f9f9fb",
      require: true,
    },
  },
  mounted() {
    this.dibuloading = true;
    homeFooter().then((res) => {
      this.dibuloading = false;
      this.introduce = res.data.other.content;
      this.menuList = res.data.other.item;
      this.jurisdiction = res.data.share.content;
      this.linkimg = res.data.share.item;
      // console.log(res.data)
    });
    serverArticle().then((res) => {
      var arr = []
      for (let i in res.data) {
        console.log(i)
        if (res.data[i].type == 1) {
          arr.push({
            title: res.data[i].title,
            aid: res.data[i].aid,
          })
        }

      }

      this.service = arr
      // this.service_agreement = res.data.service_agreement;
      // this.privacy_policy = res.data.privacy_policy;
    });
  },
  methods: {
    tolink(url) {
      window.open(url);
      // window.location.href = url
    },
    tolink2(link, type) {
      if (type == 1) {
        this.$carbon.tolink(link, 2);
      } else {
        window.open(link);
        // window.location.href = link
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dibubox {
  margin-top: 100px;
  padding: 50px 20px 30px 20px;

  .dibuflex {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    .dibuflex1 {
      width: 450px;

      .logos img {
        width: 70%;
        margin-bottom: 50px;
        cursor: pointer;
      }

      .dibu_left_content {
        color: #a0a0a0;
        font-size: 14px;
        line-height: 25px;
      }
    }

    .dibuflex2 {
      width: 740px;
      display: flex;

      justify-content: space-around;

      .dibuflx2_title {
        font-size: 17px;
        height: 40px;
        width: 120px;
      }

      .dibuflx2_content {
        width: 120px;
        font-size: 15px;
        color: #a0a0a0;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}

.dibuflex_2 {
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
  font-size: 15px;
  color: #a0a0a0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .linkflex {
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
      margin-left: 15px;
    }
  }
}

.fwxy {
  margin-left: 20px;
  cursor: pointer;
}

.imgtransition:hover{
  transform: scale(1.3, 1.3) !important;
}
</style>
