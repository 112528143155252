import request from '@/requrest/newrequest.js'

export function Login(data) { //登录接口
    return request({
        url: '/Login/login',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: false
    })
}

export function getOfficialProject(data) { //官方项目列表接口
    return request({
        url: '/goods/getOfficialProject',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function getOptionalProject(data) { //自选项目列表接口
    return request({
        url: '/goods/getOptionalProject',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}


export function userRegister(data) { //个人注册接口
    return request({
        url: '/Login/userRegister',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: false
    })
}

export function enterpriseRegister(data) { //企业注册接口
    return request({
        url: '/Login/enterpriseRegister',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: false
    })
}

export function getCodeapi(data) { //获取验证码接口
    return request({
        url: '/AliMessage/getCode',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: false
    })
}

export function getOfficialProjectDetail(data) { //官方项目详情
    return request({
        url: '/goods/getOfficialProjectDetail',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function getOptionalProjectDetail(data) { //自选项目详情
    return request({
        url: '/goods/getOptionalProjectDetail',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function AddMatchOrder(data) { //自选项目下单
    return request({
        url: '/Auth/Order/AddMatchOrder',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}


export function addOrder(data) { //官方项目下单
    return request({
        url: '/Auth/Order/addOrder',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}

export function payOrder(data) { //官方项目下单
    return request({
        url: '/Auth/Order/payOrder',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}

export function articledetail(data) { //文章详细
    return request({
        url: '/article/detail',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function articlearticleType(data) { //文章分类
    return request({
        url: '/article/articleType',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function articlelist(data) { //文章列表
    return request({
        url: '/article/list',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function holdingList(data) { //文章列表
    return request({
        url: '/Auth/Finance/holdingList',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}

export function meetingCalculate(data) { //碳会议计算
    return request({
        url: '/Calculation/scene/meetingCalculate',
        method: 'post',
        requesthead:'application/json',
        data,
        islogin: false
    })
}

export function PageInfonav(data) { //文章列表
    return request({
        url: '/PageInfo/nav',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}

export function scenedetail(data) { //碳计算详情
    return request({
        url: '/Calculation/scene/detail',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function calculationType(data) { //碳计算详情
    return request({
        url: '/Common/calculationType',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}

export function lifeParam(data) { //碳计算详情
    return request({
        url: 'Calculation/calculation/lifeParam',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: false
    })
}


export function familyCalculate(data) { //碳会议计算
    return request({
        url: '/Calculation/scene/familyCalculate',
        method: 'post',
        requesthead:'application/json',
        data,
        islogin: false
    })
}


export function carbonSell(data) { //个人碳出售
    return request({
        url: '/Auth/Sell/carbonSell',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}

export function balanceWithdrawal(data) { //提现
    return request({
        url: '/Auth/Cash/balanceWithdrawal',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}

export function getTransferRecordList(data) { //交易记录
    return request({
        url: '/Auth/Order/getTransferRecordList',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}

export function getTransactionLog(data) { //钱包余额交易记录
    return request({
        url: '/Auth/Finance/getTransactionLog',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}


export function getBalanceLog(data) { //钱包余额变动记录
    return request({
        url: '/Auth/Cash/getBalanceLog',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}


export function getSaleProductsList(data) { //在售记录
    return request({
        url: '/Auth/Sell/getSaleProductsList',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}

export function getOffShelfProductsList(data) { //下架记录
    return request({
        url: '/Auth/Sell/getOffShelfProductsList',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}


export function walletapi(data) { //钱包
    return request({
        url: '/Auth/Finance/wallet',
        method: 'get',
        requesthead:'application/x-www-form-urlencoded',
        params: data,
        islogin: true
    })
}

export function addRetireOrder(data) { //定量抵消碳出售
    return request({
        url: '/Auth/Order/addRetireOrder',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}


export function offShelfProduct(data) { //下架
    return request({
        url: '/Auth/Sell/offShelfProduct',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}


export function quantitativeRetirement(data) { //碳抵销 - 已有资产定量抵消
    return request({
        url: '/Auth/Transaction/quantitativeRetirement',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}


export function carbonRetirement(data) { //碳抵销 
    return request({
        url: '/Auth/Transaction/carbonRetirement',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}

export function enterpriseCalculate(data) { //零碳企业计算
    return request({
        url: '/Calculation/scene/enterpriseCalculate',
        method: 'post',
        requesthead:'application/json',
        data,
        islogin: false
    })
}

export function schoolCalculate(data) { //零碳学校计算
    return request({
        url: '/Calculation/scene/schoolCalculate',
        method: 'post',
        requesthead:'application/json',
        data,
        islogin: false
    })
}

export function carbonTransfer(data) { //碳转赠
    return request({
        url: '/Auth/Transaction/carbonTransfer',
        method: 'post',
        requesthead:'application/x-www-form-urlencoded',
        data,
        islogin: true
    })
}


