export default {
    notoken:'Login failed, please log in again',
    tologin:'please Login',
    qita: {
        rd: 'Confirm',
        qx: 'Cancel',
        tc: "Confirm whether to log out",
        tc2: 'Exit Login',
        grxx: 'My information',
        tx1: 'Head portrait',
        name: 'Name',
        em: 'Email',
        ph: 'Phone',
        tzc: 'Carbon Asset Amount of holding',
        ckjl: 'View Records',
        cyl: 'Hold',
        xmid: 'ID',
        xmidplpl: 'Please enter  project ID',
        tons: 'Tons',
        rgm: 'People have purchased this item',
        gjdq: 'Countries and regions',
        cyz: 'Holder',
        nf: 'Particular year',
        gm: 'Buy',
        gmtzc: 'Purchase of carbon assets',
        chlb: 'List of freely traded carbon assets',
        zanwu: 'No data',
        czgl: 'Operation management',
        cstzc: 'Sale of carbon assets',
        sq: 'Apply',
        zzsq: 'Applying',
        jyjl: 'Transaction',
        xq: 'Details',
        xmbh: 'Project ID',
        xmlb: 'Project category',
        sl: 'Quantity',
        kssj: 'Start time of crediting period',
        jssj: 'End time of crediting period',
        cs: 'Sell',
        csjg: 'Selling price',
        tps: "Tips: The selling price range is",
        cssl: 'Quantity sold',
        zsjl: 'Sales records',
        xjjl: 'Off shelf record',
        gmjl: 'Purchase record',
        xj: 'Delist',
        qrxj: 'Confirm whether to dismount?',
        ts: 'Tips',
        jg: 'Price',
        gmsl: 'Purchase quantity',
        gmrq: 'Purchase date',
        fh: 'back',
        tzchx: 'Carbon asset Retirement',
        tzcsd: 'Applying for trusteeship',
        tzcsd2: 'Applying for trust',
        hxs: 'Retire',
        qynm: 'Please enter the name or Enterprise',
        sryt: 'Please enter Retirement use',
        jxt: 'Carbon asset Retirement record',
        hxsl: 'Retirement quantity',
        cyrq: 'Holding date',
        hxsj: 'Write off time',
        hxzt: 'Retirement time',
        dsh: 'To be reviewed',
        ywc: 'Completed',
        wtg: 'Approval failed',
        zztzc: 'Transfer of carbon assets',
        zzs: 'Give',
        dfsjh: 'Please enter his phone',
        zzjls: 'Carbon asset transfer record',
        zzlx: 'Give type',
        wzzg: 'I `ll give it to',
        zzgw: 'Give it to me',
        zc1: 'Transfer out',
        zr: 'to change into',
        zzrq: 'Date of transfer',

        wdqb: 'My wallet',
        ye: 'Balance',
        yemx: 'Balance Bill',
        jyjl: 'Transaction',
        tx: 'Cash out',
        txje: 'Withdrawal amount',
        txtips: 'The service charge for withdrawal is',
        txtips2: 'After applying for cash withdrawal, we will contact you within three working days',
        jydh: 'Transaction No',
        jylx: 'Transaction Type',

        cz2: 'Recharge',
        czje: 'Recharge amount',
        sxf: 'Service Charge',
        txzt: 'State',
        txsj: 'Time',
        txje2: 'amount of money',
        ly: 'Source',

        tzcgljl: 'Carbon asset management records',
        tg1: 'Trusteeship amount',
        tg2: 'Trusteeship',
        xt1: 'Carbon Asset Trust',
        xt2: 'Carbon Asset Trust',
        sqrq: 'Date of application',
        sqttg: 'Ttrusteeship',
        sqtxt: 'Carbon trust',
        sqtips: 'After application, we will contact you within 2 working days. Are you sure to apply?',
        lxwm: 'Contact us',
        wtlx: 'Problem Type',
        xzwt: 'Please select a question type',
        mswt: 'Please describe the problem',
        lxfs: 'Please enter the contact information',
        gnyc: 'Abnormal function',
        cpjy: 'Product Suggestions',
        qtwt: 'Other issues',

        gh: 'Replace',
        xg: 'Modify',
        bcss: 'Save',
        xgmm: 'Change Password',
        xgmmtips: 'Please enter the new mobile phone number you need to replace',
        xgsjh: 'Change phone number',

        tips: 'Apply for trust of other carbon assets',
        tips2: 'Apply for trusteeship of other carbon assets',
        djzl: 'Click here',


        sqrxm: 'Applicant`s name',
        sqrxm2: 'Multiple persons separated by commas',
        sqrxm3: 'Please enter the applicant`s name',
        sqrs: 'Number of applicants',
        gmxm: 'Purchase items',
        qxzgmxm: 'Please select the item to purchase',
        dizhis: 'address',
        dizhis2: 'For mailing certificates',
        dizhis3: 'Please enter the address',
        g: 'All',
        d: 'ton',
        hj: 'total',
        bstk: 'No refund',

        tq: 'Extract',
        txtzh: 'Please enter your carbon account',

        tzxsl: "Number of carbon credits",
        yxztxysl: 'Please select the number of carbon credits',
        shpxs: 'Time sort',
        ss1: 'Ascending order',
        ss2: 'Descending order',
        jgqj: 'Price range',
        zdj: 'minimum price',
        zgj: 'Maximum price',
        chongz: 'Reset',
        shaixuans: 'Filter',
        zzcaoz: 'Termination',
        buys:"Buy",
        hzzy:"Certified Voluntary Emission Reductions",
        zcdl:'Sign up / Login',
        zcdlsu:'Registrasi Success',
        zcdlcode: 'Verification code sent success',
        ksjy:"Take action",

        xw:"Climate information",
        gdss:"more",
        chsq:'withdraw',
        qrchsq:'Are you sure you want to withdraw',
        cz:'Operate',
		sl:"Quantity",
		zc:'Money',
		sj:'Time',
        jlmx:'Carbon Asset Record Details',
        jl:'Record',
        hxhb:"Retirement poster",

        pm:'NO',
		tx1:'Avatar',
		yhm:'name',
		thxl:'Quantity',

        gnkf:'The function is under development, please stay tuned',
        fhsy:'Back Home',

        pfanngfs:'Carbon emissions',
        dxiaofs:'Retirement method',
        zchbxms:'Support environmental protection projects',
        shiyyue:'Use account carbon credit balance',
        syengyus:'residue',
        xmchecks:'Project Selection',
        xmdanjias:'Project unit price',
        zfjine:'Payment amount',
        quxioaos:'Cancel',
        quedings:'Enter',
        zanwuchisyoas:'There are currently no projects available',
        chiyoushuliangs:'Holding',
        dixiaohou:"Retirement residue",
        hexiaoyhut:'Retirement use',
        hexiaoyhutss:'Pleas Enter the Retirement use',
        cyslbz:'Insufficient holding quantity',

    },
    headtitle: {
        shouye: 'Home',
        carbonguanli: 'Management',
        carjiaoyi: 'Trading',
        carkuaiji: 'Accounting',
        my: 'My',
        jtjy: 'Zerocarbon Home',
        jc: 'Carbon Asset Deposit',
        tg: 'Carbon Asset Trusteeship',
        jr: 'Carbon Finance',
        grxx: 'My Information',
        wdtzh: 'My Account',
        yjfk: 'Contact Us',
        ota: 'OTC Sale',
        ch: 'Carbon asset matching',

        ltgm: 'Zero Carbon Citizen / Family',
        ltqy: 'Zero Carbon Enterprise / School',
        lthy: 'Zero Carbon Conference / Event'
    },
    building: 'Under development',
    lg: 'EN',
    btn: {
        shenq: 'Apply',
        login: 'Login',
        zhuce: 'Registered account',
        wjpwd: 'Forgot password'
    },
    ipt: {
        area: 'Area',
        phone: 'Please enter your phone',
        pwd: 'Please enter the password',
        xzarea: 'Please select the area code',
        qymc: 'Please enter the enterprise name',
        xmfzr: 'Please enter the name of the project leader',
        lxyx: 'Please enter email.',
        yx2: "The email format is incorrect",
        rzjg: 'Carbon assets certification authority',
        xmbh: 'Please enter project ID',
        xmmcname:'Project name',
        xmmc: 'Please enter the project name',
        xmlb: 'Please select an item category',
        txynf: 'Carbon credit vintage',
        qxztxynf: "Please select the Carbon credit vintage",
        xzrq: 'Select date',
        xmxlh: 'Please enter Carbon Asset Serial number',
        txsld: 'Please enter the quantity(ton)',
        xmjrrq: 'Project recording date',
        xzjrrq: 'Please select Crediting Period Date',
        kssr: 'Start Date',
        jssr: 'End Date',
        geren: 'Individual',
        qiye: 'Enterprise ',
        txxm: 'Please enter your name',
        codes: 'Please enter the verification code',
        getyzm: 'Get code',
        txem: 'Please enter email.',
        mm: 'Please enter the password',
        mm2: 'Please enter the password again',
        mm3: 'The passwords entered twice are inconsistent',
        qxz: 'Please select',
        xzxmlb: 'Please select an item category',
        rygm: 'Please select personnel size',
    },
    buy: {
        gmds: 'Amount of Purchase (Ton)',
        qsy: 'Please use',
        zf: 'Pay',
        zh: 'Receivables account',
        je: 'Payment',
        tips: 'Tip: After the payment is completed, please upload the payment Filtershot, we will review for you',
        fkjt: "Filtershot of payment",
        dj: 'Click to select a picture',
        copy: 'Copy succeeded',
        qsc: 'Please upload the Filtershot',
        djfz: 'Click Copy',
        scsb: 'Image upload failed, please try again',
        cyl: 'Amount of hold',
        xmid: 'ProjectID',
        gmrs: 'Purchase this item',
        gjdq: 'Countries and regions',
        cyz: 'Holder',
        xmnf: 'Project Year',
        sy: 'Surplus',
        buy: 'Buy',
        sub: 'Submit',

        cyz: 'Holder',
        xmbh: 'Project ID',
        xmlb: 'Project category',
        nf: 'Year',
        sl: 'Quantity',
        jrq: 'Start time of crediting period',
        jsq: 'End time of crediting period',
        tpxz: 'Uploaded pictures can only be in jpg, jpeg, png or gif format!!!',

        gmnf: 'Year of purchase',
        gmsl: 'quantity',


        zfcg: 'Payment successful',
        cgtips: 'The carbon asset project will be credited within ten minutes, and you will be notified via text message. Please be patient and wait',
        zfsb: 'Payment failed',
        sbtips: `
        <div>Please check the following issues</div>
        <div>1.You have cancelled PayPal payment</div>
        <div>2.Your PayPal balance is insufficient. Please ensure that the balance is sufficient</div>
        <div>3.Your payment amount has exceeded the set online payment limit</div>
        <div>4.You have not yet activated the online payment function. Please activate this function before making the payment。</div>
        `,
        wdzh: 'My carbon account',

        zfje: 'Payment amount',
        yezf: 'Balance payment',
        yebz: 'Balance payment',
        dqye: 'The current balance is',
        zf: 'Pay',
        ddyc: 'Order exception, please try again'
    },
    
    tkj:{
        tcyl:'Hold',
        fzlj:'Copy Link',
        fzcg:'Copy Success',
        fx:'Share',
        xz:'Medal',
        phb:'Ranking',
        sys:'Calculate',
        jsjg:'Calculate Eesult',
        ndtpfw:'Your Carbon Emissions are',
        qx:"Cancel",
        hx:"Retire",
        thxl:'Quantity',
        cg:"Exceed",
        yh:'User',
        phb:'Ranking',
        ryxz:'Medal of honor',
        jlmx:'Record Details',
        djsm:'Level Description',
        thxphb:'Carbon Ranking',
        gxsj:'Update Time',
        ywc:'Completed',
        wwc:'Incomplete',
        lx:'Type',
        sj:'Time',
        dj:'Grade',
        ch:'Appellation',

        qt:'Other',
        y:'Clothing',
        s:'Eat',
        z:'live',
        x:'Travel',
        y:'Use',
        xzrq:'Select Date',
        ly:'Last 6 Months',
        yn:'Last Year',
        wn:'Last 5 Years',
        kpj:'Opening Price',
        zjs:'Yesterday settlement',
        zgj:'',
        zdj:'',
        jg:'price',
        trsj:"Please fill in the data",
        xzapp:'Download App'

      
    },
    metting: {
        dates: 'Select Date',
        jbts: 'Please fill in the number of days held',
        cyrs: 'Please fill in the number of participants',
        gzrs: 'Please fill in the number of visitors',
        gs: 'Estimate',
        js2: 'Actuary',
        xyb: 'next',
        mswz: 'Descriptive text',
        xzdq: 'Select Region',
        zj: 'Total carbon emissions',
        mxrx: 'The details are as follows',
        zbf: 'Please fill in the organizer `s unit',
        hymc: 'Please fill in the name of the meeting/event',
        fzr: 'Please fill in the name of the project leader',
        zhzb: 'Select Neutrality Indicator',
        shuom: 'Description: The system will generate a description template in advance based on the content you enter, and you can also edit the description content yourself',
        fkzje: 'Total payment amount',
        syb: 'Back',
        qued: 'confirm',
        fkfs: 'Please select payment method',
        fkzje: 'Total payment amount',
        yezf: 'Balance payment',
        dqye: 'The current balance is',
        zf: 'payment',
        qx: 'Cancel',
        yebz: 'Insufficient balance, please use PayPal for payment',
        zfcg: 'Payment successful',
        txwzxx: 'Please fill in the complete information',
        ren: 'people',
        zai: 'exist',
        jubanle: 'A duration of',
        shenq: 'Day application',
        tzh: 'Perform carbon neutrality',
        js: 'count',
        qrxx: 'Confirm',
        qtxms: 'Please fill in the description',
    },
    upl:{
        text1:"Want to have a more complete interactive experience?",
        text2:"Download our app and enjoy a low-carbon lifestyle immediately",
        text3:'Scan QR code for download',
        text4:'Are you ready for a low-carbon journey?',
        text5:"Let's lighten the burden on the earth together!",
        text6:"",
        carousel:[
            {
                img:require('@/assets/enupl_1.png'),
                title:'Calculate personal carbon emissions'
            },{
                img:require('@/assets/enupl_2.png'),
                title:'Learn one carbon knowledge every day'
            },{
                img:require('@/assets/enupl_3.png'),
                title:'Explore green,Learning green knowledge'
            }
        ]

    },
    count:{
        qxzjd:'Please select a home appliance',
        pflzj:'Total carbon emissions',
        mxrx:'The details are as follows',
        syb:'Back',
        xyb:'Next',
        hexiao:'Offset',
        js:'Count',
        dxsl:'Offset quantity cannot be 0',
        shul:'number',
        kssj:'time-on',
        jtrk:"Household Size",
        zfmj:'Please select the housing area',
        renshu:'Number of people',
        jbts:"Please enter the number of days held",
        cyrs:'Please enter the number of participants',
        gzrs:'Please enter the number of visitors',
        sellsucccess:'Success',
        remsuccess:'Success',


        school:'Please enter the name of the school',
        schooltype:'Please select the type of school',
        mianji:"Please enter the building area",
        shishen:'Please enter the number of teachers and students',
        xuanzetime:'Please select a time',

        qymc:"Please enter the company name",
        hyleix:'Please select industry type',
        qiyencz:'Please enter the annual output value of the enterprise',
        wanyuan:"ten thousand",
        jbxxs:'Information'
    }
}