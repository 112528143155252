<template>
  <div id="app">
    <heads />
    <div class="">
      <router-view />
    </div>
    <div>
      <dibu v-show="$route.meta.keepAlive" :color="color" />
    </div>
  </div>
</template>
<script>
import heads from "@/components/headtitle/head.vue";
import dibu from "@/components/dibu/dibu.vue";
export default {
  components: {
    heads,
    dibu,
  },
  data() {
    return {
      color: "",
    };
  }, 
  watch: { 
    $route(to, from) {
      if (to.path == "/zerocarbonhome" || to.path == "/news") {
        if (to.path == from.path) {
          this.$router.go(0);
        }
      }
      console.log(this.$route.meta)
      document.documentElement.scrollTop = 0;  
      if (this.$route.meta.bottomcolor) {
        this.color = this.$route.meta.bottomcolor;
      } else {
        this.color = "#f9f9fb";
      }
    },
  },
  mounted() {
    
    var w = window.screen.width;
    w = parseInt(w);
    if (760 > w) {
      if (this.$carbon.version == 1){
       location.href = "https://www.zerocarbonex.com/m/index.html";
      }else if (this.$carbon.version == 3){
        location.href = "https://www.zerocarbonx.net/m/#/";
      }
      

    }
  },
};
</script>

<style lang="scss">

@media (max-width: 1480px) {
  .wid1280 {
    padding: 0 120px !important;
  }
  
}

// @import url();
.carsellbox {
  padding: 0 15px;
  margin-top: 20px;
  font-size: 15px;
  > div {
    display: flex;
    margin-bottom: 14px;
    > div:first-child {
      width: calc(50% - 70px);
    }
    > div:last-child {
      width: calc(50%);
      margin-left: 20px;
    }
  }
}
.sqt {
  text-align: center;
  img {
    width: 30%;
    margin-top: 100px;
  }
  div {
    margin-top: 20px;
    font-size: 18px;
    color: #b3b3b3;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
  // background: #f7f7f7;
}
.wid1480 {
  margin: 0 auto;
  width: calc(1480px - 40px);
  padding: 0 20px;
}
.wid1280 {
  margin: 0 auto;
  width: calc(1280px - 40px);
  padding: 0 20px;
}
.mgtp {
  margin-top: 60px;
}
.imgbox {
  img {
    width: 100%;
  }
}
.bors {
  border-radius: 20px;
}
.backcolor {
  background-color: #f9f9fb;
}

.ellipsisThree {
  overflow: hidden;
  text-overflow: ellipsis;
  // word-break: break-all;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.ellipsissex {
  overflow: hidden;
  text-overflow: ellipsis;
  // word-break: break-all;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.ellipsisfor {
  overflow: hidden;
  text-overflow: ellipsis;
  // word-break: break-all;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.ellipsisTwo {
  overflow: hidden;
  text-overflow: ellipsis;
  // word-break: break-all;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
.studyVideoBoxRight ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.studyVideoBoxRight ul::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #64ceff;
}

// 以下是动画效果
.changebackground {
  transition-duration: 0.4s;
}
.changebackground:hover,
.changebackground > .titles:hover {
  background-color: #ecf6ff !important;
  // transition: all .5s;
  color: #00a0e9;
}

.imgtransition {
  transition-duration: 0.4s;
}
.imgtransition:hover {
  transform: scale(1.03, 1.03) !important;
}

.changeborder {
  transition-duration: 0.4s;
}
.changeborder:hover {
  border: 1px solid #d4e6ee !important;
}

.backs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  >div:first-child{
    display: inline-block;
    background: #00a0e9;
    color: white;
    padding: 6px 15px;
    border-radius: 20px;
    cursor: pointer;
  }

  .takenotes{
    display: inline-block;
    background: #ecf6ff;
    color: #00a0e9;
    padding: 6px 15px;
    border-radius: 20px;
    cursor: pointer;
  }
}
.cupo{
  cursor: pointer;
}
.backs>div:first-child{
  transition-duration: 0.4s;
  border: 1px solid white;
}
.backs >div:first-child:hover{
  border: 1px solid #00a0e9 !important;
}
.imghoverboxshadow{
    transition-duration: 0.5s;
}
.imghoverboxshadow:hover{
  box-shadow: 1px 0px 9px 1px #8fbfd7 !important;
  transform: scale(1.01, 1.01) !important;
  // filter: drop-shadow(0 0 5px #8fbfd7) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

</style>
