export default {
    notoken:'Login gagal, silakan log masuk lagi',
    tologin:'please Login',
    qita: {
        rd: 'konfirmasi',
        qx: 'batalkan',
        tc: "Konfirmasi apakah untuk log keluar",
        tc2: 'Daftar keluar dari daftar masuk',
        grxx: 'Informasi saya',
        tx1: 'Avatar',
        name: 'Nama',
        em: 'kotak surat',
        ph: 'Nomor ponsel',
        tzc: 'Pemilikan aset karbon',
        ckjl: 'Lihat Rekaman',
        cyl: 'Pertahanan',
        xmid: 'ID Proyek',
        xmidplpl: 'Silakan isi ID proyek',
        tons: 'ton',
        rgm: 'Orang-orang telah membeli barang ini',
        gjdq: 'Negara/wilayah',
        cyz: 'pemegang',
        nf: 'Tahun',
        gm: 'membeli',
        gmtzc: 'Pembelian aset karbon',
        chlb: 'Daftar aset karbon yang diberi perdagangan bebas',
        zanwu: 'Tidak ada data yang tersedia',
        czgl: 'Manajemen Operasi',
        cstzc: 'Menjual aset karbon',
        sq: 'aplikasi',
        zzsq: 'Menggunakan',
        jyjl: 'Catatan transaksi',
        xq: 'rincian',
        xmbh: 'Nomor proyek',
        xmlb: 'Kategori proyek',
        sl: 'jumlah',
        kssj: 'Waktu memulai periode akuntansi',
        jssj: 'Waktu akhir periode akuntansi',
        cs: 'menjual',
        csjg: 'Harga penjualan',
        tps: "Ingatan: Jarak harga penjualan adalah antara",
        cssl: 'Jumlah jualan',
        zsjl: 'Dalam catatan penjualan',
        xjjl: 'Atas rekaman rak',
        gmjl: 'Catatan Pembelian',
        xj: 'Off rak',
        qrxj: 'Confirm whether to remove from the shelf?',
        ts: 'prompt',
        jg: 'harga',
        gmsl: 'Kuantitas pembelian',
        gmrq: 'Tarikh pembelian',
        fh: 'kembali',
        tzchx: 'Offset aset karbon',
        tzcsd: 'Memaplikasi untuk hosting',
        tzcsd2: 'Meminta kepercayaan',
        hxs: 'ofset',
        qynm: 'Silakan masukkan nama orang ofset atau nama perusahaan',
        sryt: 'Silakan masukkan tujuan ofset',
        jxt: 'Rekaman offset aset karbon',
        hxsl: 'Kuantitas ofset',
        cyrq: 'Tanggal menahan',
        hxsj: 'Waktu Offset',
        hxzt: 'Status Offset',
        dsh: 'Revisi tunggu',
        ywc: 'Audit berhasil',
        wtg: 'Review gagal',

        zztzc: 'Transfer aset karbon',
        zzs: 'Transfer',
        dfsjh: 'Silakan masukkan nomor telepon pihak lain',
        zzjls: 'Catatan transfer aset karbon',
        zzlx: 'Jenis transfer',
        wzzg: 'Aku memindahkannya ke',
        zzgw: 'Transfer kepada saya',
        zc1: 'Transfer keluar',
        zr: 'Transfer masuk',
        zzrq: 'Tarikh transfer',

        wdqb: 'Dompetku.',
        ye: 'balance',
        yemx: 'Perincian keseimbangan',
        jyjl: 'Catatan transaksi',
        tx: 'Pengunduran',
        txje: 'Jumlah tarik',
        txtips: 'Gaji layanan ditarik adalah',
        txtips2: 'Setelah meminta penarikan diri, kami akan menghubungimu dalam tiga hari kerja.',
        jydh: 'Nomor transaksi',
        jylx: 'Jenis transaksi',
        cz2: 'Isi ulang',
        czje: 'Jumlah muat ulang',
        sxf: 'Gaji pengurusan',
        txzt: 'keadaan',
        txsj: 'waktu',
        txje2: 'uang',
        ly: 'sumber',
        tzcgljl: 'Catatan manajemen aset karbon',
        tg1: 'Volum penjagaan',
        tg2: 'trusteeship',
        xt1: 'Jumlah kepercayaan',
        xt2: 'trust',
        sqrq: 'Tarikh aplikasi',
        sqttg: 'Apply for carbon custody',
        sqtxt: 'Aplikasi untuk kepercayaan karbon',
        sqtips: 'Setelah melaksanakan aplikasi, kami akan menghubungi Anda dalam waktu 2 hari kerja. Apakah Anda yakin Anda ingin melaksanakan aplikasi?',
        lxwm: 'hubungi kami',
        wtlx: 'Jenis Masalah',
        xzwt: 'Silakan pilih jenis pertanyaan',
        mswt: 'Tolong jelaskan masalah yang telah terjadi',
        lxfs: 'Silakan isi informasi kontak',
        gnyc: 'Fungsi abnormal',
        cpjy: 'Rekomendasi produk',
        qtwt: 'Masalah lain',

        gh: 'ganti',
        xg: 'ubah',
        bcss: 'simpan',
        xgmm: 'Ubah kata sandi',
        xgmmtips: 'Silakan masukkan nomor telepon baru yang Anda butuhkan untuk menggantikan',
        xgsjh: 'Ubah nomor telepon',
        tips: 'Aplikasi untuk kepercayaan aset karbon lainnya',
        tips2: 'Apply for custody of other carbon assets',
        djzl: 'klik di sini',

        sqrxm: 'Nama Applicant',
        sqrxm2: 'Berpisahkan banyak orang dengan koma',
        sqrxm3: 'Silakan isi nama aplikasi',
        sqrs: 'Jumlah aplikant',
        gmxm: 'Membeli item',

        qxzgmxm: 'Silakan pilih item untuk dibeli',
        dizhis: 'alamat',
        dizhis2: 'Digunakan untuk mengirim sertifikat',
        dizhis3: 'Silakan isi alamat',
        g: 'umum',
        d: 'ton',
        hj: 'total',
        bstk: 'Tidak ada kembalian',
        tq: 'ekstrak',
        txtzh: 'Silakan isi rekening karbon Anda',

        tzxsl: "Jumlah kredit karbon",
        yxztxysl: 'Silakan pilih nomor kredit karbon',
        shpxs: 'Pengurusan waktu',
        ss1: 'Perintah meningkat',
        ss2: 'Order turun',
        jgqj: 'Jarak harga',
        zdj: 'Harga paling rendah',
        zgj: 'Harga maksimum',
        chongz: 'Reset',
        shaixuans: 'screen',
        zzcaoz: 'penghentian',

        buys:"membeli",
        hzzy:"Certified voluntary emission reduction",
        zcdl:'Registrasi / Login',
        zcdlsu:'Registrasi Success',
        zcdlcode: 'Kode pengesahan dikirim dengan sukses',
        ksjy:"Ambil tindakan segera",

        xw:"Climate information",
        gdss:"lebih",
        chsq:'Pengunduran aplikasi',
        qrchsq:'Apakah Anda yakin ingin menarik aplikasi',
        cz:'operasi',
		sl:"jumlah",
		zc:'aset',
		sj:'waktu',
        jlmx:'Rincian Catatan Aset Carbon',
        jl:'rekaman',

        hxhb:"Poster Offset",

        pm:'peringkat',
		tx1:'Avatar',
		yhm:'nama pengguna',
		thxl:'Jual offset karbon',
        gnkf:'Fungsinya sedang dikembangkan, tolong tetap tuned',
        fhsy:'Return to homepage',

        pfanngfs:'Emisi karbon',
        dxiaofs:'Metode ofset',
        zchbxms:'mendukung proyek perlindungan lingkungan',
        shiyyue:'Gunakan bilangan kredit karbon',
        syengyus:'surplus',
        xmchecks:'Pilihan Proyek',
        xmdanjias:'Harga unit proyek',
        zfjine:'Jumlah pembayaran',
        quxioaos:'batalkan',
        quedings:'konfirmasi',
        zanwuchisyoas:'Tidak ada proyek yang memegang aset karbon',
        chiyoushuliangs:'Menguasai',
        dixiaohou:"Jumlah yang tersisa setelah kompensasi",
        hexiaoyhut:'Tujuan Offset',
        hexiaoyhutss:'Silakan isi tujuan ofset',
        cyslbz:'Tidak cukup kuantitas pemegang',

    },

    headtitle: {
        shouye: 'halaman rumah',
        carbonguanli: 'Manajemen karbon',
        carjiaoyi: 'perdagangan emisi karbon',
        carkuaiji: 'Perhitungan karbon',
        my: 'Halaman rumahku',
        jtjy: 'Rumah karbon nol',
        jc: 'Depositi Aset Carbon',
        tg: 'Carbon Asset Custody',
        jr: 'Carbon Asset Trust',

        grxx: 'Informasi saya',
        wdtzh: 'Akaunku',
        yjfk: 'hubungi kami',
        ota: 'Penjualan OTC',
        ch: 'Penyesuaian perdagangan emisi karbon',

        ltgm: 'Warga karbon nol',
        ltqy: 'Perusahaan karbon nol',
        lthy: 'Zero Carbon Conference'


    },
    building: 'Tetap tuned',
    lg: 'ID',
    btn: {
        shenq: 'aplikasi',
        login: 'Login',
        zhuce: 'Daftar rekening',
        wjpwd: 'Lupakan kata sandi',
        zhuce: 'register'
    },
    ipt: {
        area: 'Kode area',
        phone: 'Silakan isi nomor telepon Anda',
        pwd: 'Silakan isi kata sandi',
        xzarea: 'Silakan pilih kode area',
        qymc: 'Silakan isi nama perusahaan',
        xmfzr: 'Silakan isi nama pemimpin proyek',
        lxyx: 'Silakan isi alamat email',
        yx2: "Format email yang salah",
        rzjg: 'Autoritas Sertifikasi Aset Carbon',
        xmbh: 'Please fill in the project number',
        xmmcname: 'nama masukan',
        xmmc: 'Silakan isi nama proyek',
        xmlb: 'Silakan pilih sebuah kategori proyek',
        txynf: 'Tahun kredit karbon',
        qxztxynf: "Silakan pilih tahun kredit karbon",
        xzrq: 'Select date',
        xmxlh: 'Silakan isi nomor seri kredit karbon',
        txsld: 'Silakan isi jumlah (ton)',
        xmjrrq: 'Tarikh inklusi proyek',
        xzjrrq: 'Silakan pilih tanggal ketika proyek termasuk',
        kssr: 'Tarikh awal',
        jssr: 'Tarikh akhir',



        geren: 'Registrasi pribadi',
        qiye: 'Registrasi Perusahaan',
        txxm: 'Silakan isi nama Anda',
        codes: 'Silakan isi kode verifikasi',
        getyzm: 'Obtain verification code',
        txem: 'Silakan isi alamat email',
        mm: 'Silakan isi kata sandi',
        mm2: 'Silakan masukkan sandi lagi',
        mm3: 'Dua sandi yang dimasukkan tidak cocok',
        qxz: 'Silakan pilih',
        xzxmlb: 'Silakan pilih sebuah kategori proyek',
        rygm: 'Silakan pilih ukuran pribadi',



    },
    buy: {
        gmds: 'Pembelian Tonnage',
        qsy: 'Silakan gunakan',
        zf: 'pembayaran',
        zh: 'Collection account',
        je: 'Jumlah pembayaran',
        tips: 'Reminder: After the payment is completed, please upload a screenshot of the payment and we will review it for you',
        fkjt: "Skrin pembayaran",
        dj: 'Klik untuk memilih gambar',
        copy: 'Disalin dengan sukses',
        qsc: 'Silakan mengunggah foto layar',
        djfz: 'Klik untuk menyalin',
        scsb: 'Pemuat gambar gagal, silakan coba lagi',
        cyl: 'Pertahanan',
        xmid: 'ID Proyek',
        gmrs: 'Orang-orang telah membeli proyek ini',
        gjdq: 'Negara/wilayah',
        cyz: 'pemegang',
        xmnf: 'Tahun Proyek',
        sy: 'surplus',
        buy: 'membeli',
        sub: 'Kirim',

        cyz: 'pemegang',
        xmbh: 'Nomor proyek',
        xmlb: 'Nomor proyek',
        nf: 'Tahun',
        sl: 'jumlah',
        jrq: 'Waktu memulai periode akuntansi',
        jsq: 'Waktu akhir periode akuntansi',
        tpxz: 'Memuat naik gambar hanya dapat dalam format jpg, jpeg, png, atau gif!!!',



        gmnf: 'Tahun pembelian',
        gmsl: 'Kuantitas pembelian',


        zfcg: 'Pembayaran berhasil',
        cgtips: 'Proyek aset karbon diharapkan tiba dalam 10 menit, dan Anda akan diberitahu oleh SMS. Silakan bersabar dan tunggu',
        zfsb: 'Pembayaran gagal',
        sbtips: `
        <div>Silakan periksa masalah berikutnya</div>
        <div>1.Anda telah membatalkan pembayaran PayPal</div>
        <div>2.Balansi PayPal Anda tidak cukup. Silakan pastikan bilangannya cukup</div>
        <div>3.Jumlah pembayaran Anda telah melebihi batas pembayaran online yang ditetapkan</div>
        <div>4.Anda belum mengaktifkan fungsi pembayaran online. Silakan mengaktifkan fungsi ini sebelum membuat pembayaran.</div>
        `,
        wdzh: 'Akun karbon saya',

        zfje: 'Jumlah pembayaran',
        yezf: 'Balance payment',
        yebz: 'Insufficient balance',
        dqye: 'Kesimbangan saat ini adalah',
        zf: 'pembayaran',
        ddyc: 'Pengecualian perintah, tolong coba lagi'


    },

    tkj:{
        tcyl:'Pertahanan karbon',
        fzlj:'Salin Link',
        fzcg:'Disalin dengan sukses',
        fx:'berbagi',
        xz:'medal',
        phb:'Ranking',
        sys:'tambah',
        jsjg:'Hasil kalkulasi',
        ndtpfw:'Your carbon emissions are',
        qx:"batalkan",
        hx:"ofset",
        thxl:'Jual offset karbon',
        cg:"melebihi",
        yh:'pengguna',
        phb:'Ranking',
        ryxz:'Medal kehormatan',
        jlmx:'Rincian Rekam',
        djsm:'Huraian Tingkat',
        thxphb:'Ranking Offset Carbon',
        gxsj:'Waktu kemaskini',
        ywc:'Selesai',
        wwc:'Tidak lengkap',
        lx:'tipe',
        sj:'waktu',
        dj:'kelas',
        ch:'title',

        qt:'lain',
        y:'pakaian',
        s:'makanan',
        z:'hidup',
        x:'baris',
        y:'menggunakan',
        xzrq:'Select date',
        ly:'6 bulan terakhir',
        yn:'Pada tahun terakhir',
        wn:'Dalam 5 tahun terakhir',
        kpj:'Harga pembukaan',
        zjs:'Keselesaian kemarin',
        zgj:'',
        zdj:'',
        jg:'harga',
        trsj:"Silakan isi data",
        xzapp:'Memindai kode untuk mengunduh aplikasi'
    },
    metting: {
        dates: 'Pilih tanggal',
        jbts: 'Silakan isi jumlah hari yang disimpan',
        cyrs: 'Silakan isi jumlah peserta',
        gzrs: 'Silakan isi jumlah pengunjung',
        gs: 'perkiraan',
        js2: 'aktiar',
        xyb: 'Langkah berikutnya',
        mswz: 'Teks deskriptif',
        xzdq: 'Pilih Daerah',
        zj: 'Total emisi karbon',
        mxrx: 'Perincian adalah seperti ini',
        zbf: 'Silakan isi unit organisasi',
        hymc: 'Silakan isi nama pertemuan / peristiwa',
        fzr: 'Silakan isi nama pemimpin proyek',
        zhzb: 'Pilih Indikator Neutralitas',
        shuom: 'Deskripsi: Sistem akan menghasilkan templat deskripsi secara awal berdasarkan isi yang Anda masukkan, dan Anda juga dapat edit isi deskripsi sendiri',
        fkzje: 'Total payment amount',
        syb: 'Langkah sebelumnya',
        qued: 'konfirmasi',
        fkfs: 'Silakan pilih metode pembayaran',
        fkzje: 'Jumlah pembayaran total',
        yezf: 'Balance payment',
        dqye: 'Kesimbangan saat ini adalah',
        zf: 'Pembayaran',
        qx: 'batalkan',
        yebz: 'Saldo yang tidak cukup, silakan gunakan PayPal untuk membayar',
        zfcg: 'Pembayaran berhasil',
        txwzxx: 'Silakan isi informasi lengkap',
        ren: 'orang',
        zai: 'Tetap',
        jubanle: 'Sebuah durasi',
        shenq: 'Day application',
        tzh: 'Lakukan otoritas karbon',
        js: 'kalkulasi',
        qrxx: 'Informasi Konfirmasi',
        qtxms: 'Silakan isi deskripsi',
    },
    upl:{
        text1:"Want to have a more complete interactive experience?",
        text2:"Download our app and enjoy a low-carbon lifestyle immediately",
        text3:'Scan QR code for download',
        text4:'Are you ready for a low-carbon journey?',
        text5:"Let's lighten the burden on the earth together!",
        text6:"",
        carousel:[
            {
                img:require('@/assets/enupl_1.png'),
                title:'Calculate personal carbon emissions'
            },{
                img:require('@/assets/enupl_2.png'),
                title:'Learn one carbon knowledge every day'
            },{
                img:require('@/assets/enupl_3.png'),
                title:'Explore green,Learning green knowledge'
            }
        ]
    },
    count:{
        qxzjd:'Please select a home appliance',
        pflzj:'Total carbon emissions',
        mxrx:'The details are as follows',
        syb:'Back',
        xyb:'Next',
        hexiao:'Offset',
        js:'Count',
        dxsl:'Offset quantity cannot be 0',
        shul:'number',
        kssj:'time-on',
        jtrk:"Household Size",
        zfmj:'Please select the housing area',
        renshu:'Number of people',
        jbts:"Please enter the number of days held",
        cyrs:'Please enter the number of participants',
        gzrs:'Please enter the number of visitors',
        sellsucccess:'Success',
        remsuccess:'Success',

        school:'Please enter the name of the school',
        schooltype:'Please select the type of school',
        mianji:"Please enter the building area",
        shishen:'Please enter the number of teachers and students',
        xuanzetime:'Please select a time',

        qymc:"Please enter the company name",
        hyleix:'Please select industry type',
        qiyencz:'Please enter the annual output value of the enterprise',
        wanyuan:"ten thousand",
        jbxxs:'Information'
    }


}