import request from '@/requrest/request.js'
export function homePage(data) { //首页数据
    return request({
        url: '/api/v1/index/homePage',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function resource(data) { //详情数据接口
    return request({
        url: '/api/v1/index/resource',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function lifeParam(data) { //详情数据接口
    return request({
        url: '/api/v1/Calculation/lifeParam',
        method: 'get',
        params: data,
        islogin: true
    })
}


export function homeFooter(data) { //底部数据
    return request({
        url: '/api/v1/index/homeFooter',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function areaCode(data) { //area
    return request({
        url: '/api/v1/Common/areaCode',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function login(data) {
    return request({
        url: '/api/v1/Login/login',
        method: 'post',
        data,
        islogin: false
    })
}

export function lifeCalculate(data) {
    return request({
        url: '/api/v1/Calculation/lifeCalculate',
        method: 'post',
        data,
        islogin: true
    })
}


export function center(data) {
    return request({
        url: '/api/v1/User/center',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function rankList(data) {
    return request({
        url: '/api/v1/Medal/rankList',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function medalList(data) {
    return request({
        url: '/api/v1/Medal/medalList',
        method: 'get',
        params: data,
        islogin: true
    })
}


export function assetType(data) {
    return request({
        url: '/api/v1/Common/assetType',
        method: 'get',
        params: data,
        islogin: false
    })
}


export function carbonHold(data) {
    return request({
        url: '/api/v1/Transaction/carbonHold',
        method: 'post',
        data,
        islogin: true
    })
}


export function sendSms(data) {
    return request({
        url: '/api/v1/Common/sendSms',
        method: 'post',
        data,
        islogin: false
    })
}

export function userRegister(data) {
    return request({
        url: '/api/v1/Login/userRegister',
        method: 'post',
        data,
        islogin: false
    })
}

export function businessRegister(data) {
    return request({
        url: '/api/v1/Login/businessRegister',
        method: 'post',
        data,
        islogin: false
    })
}

export function industryType(data) {
    return request({
        url: '/api/v1/Common/industryType',
        method: 'get',
        params: data,
        islogin: false
    })
}
export function staffSize(data) {
    return request({
        url: '/api/v1/Common/staffSize',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function trusteeshipLog(data) {
    return request({
        url: '/api/v1/Finance/trusteeshipLog',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function wallet(data) {
    return request({
        url: '/api/v1/Finance/wallet',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function projectList(data) {
    return request({
        url: '/api/v1/Shop/projectList',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function projectDetail(data) {
    return request({
        url: '/api/v1/Shop/projectDetail',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function serverArticle(data) {
    return request({
        url: '/api/v1/Common/serverArticle',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function payConfig(data) {
    return request({
        url: '/api/v1/Cash/payConfig',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function uploadImg(data) {
    return request({
        url: '/api/v1/uploadImg',
        method: 'post',
        data,
        islogin: false
    })
}

export function addOrder(data) {
    return request({
        url: '/api/v1/order/addOrder',
        method: 'post',
        data,
        islogin: true
    })
}


export function Matchmaking(data) {
    return request({
        url: '/api/v1/Shop/Matchmaking',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function matchingDetail(data) {
    return request({
        url: '/api/v1/Shop/matchingDetail',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function addMatchorder(data) {
    return request({
        url: '/api/v1/order/addMatchorder',
        method: 'post',
        data,
        islogin: true
    })
}

export function holdingList(data) {
    return request({
        url: '/api/v1/Finance/holdingList',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function carbonSell(data) {
    return request({
        url: '/api/v1/Transaction/carbonSell',
        method: 'post',
        data,
        islogin: true
    })
}

export function saleRecord(data) {
    return request({
        url: '/api/v1/Shop/saleRecord',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function offProject(data) {
    return request({
        url: '/api/v1/Shop/offProject',
        method: 'post',
        data,
        islogin: true
    })
}


export function downRecord(data) {
    return request({
        url: '/api/v1/Shop/downRecord',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function orderList(data) {
    return request({
        url: '/api/v1/order/orderList',
        method: 'get',
        params: data,
        islogin: true
    })
}


export function carbonWritroff(data) {
    return request({
        url: '/api/v1/Transaction/carbonWritroff',
        method: 'post',
        data,
        islogin: true
    })
}

export function writeoffList(data) {
    return request({
        url: '/api/v1/Finance/writeoffList',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function carbonSubgift(data) {
    return request({
        url: '/api/v1/Transaction/carbonSubgift',
        method: 'post',
        data,
        islogin: true
    })
}

export function transferRecord(data) {
    return request({
        url: '/api/v1/Finance/transferRecord',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function transactionLog(data) {
    return request({
        url: '/api/v1/Cash/transactionLog',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function transactionLog2(data) {
    return request({
        url: '/api/v1/Finance/transactionLog',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function userCash(data) {
    return request({
        url: '/api/v1/Cash/userCash',
        method: 'post',
        data,
        islogin: true
    })
}

export function trusteeshopList(data) {
    return request({
        url: '/api/v1/Finance/trusteeshopList',
        method: 'get',
        params: data,
        islogin: true
    })
}


export function bankingList(data) {
    return request({
        url: '/api/v1/Finance/bankingList',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function carbonTrusteeship(data) {
    return request({
        url: '/api/v1/Transaction/carbonTrusteeship',
        method: 'post',
        data,
        islogin: true
    })
}

export function carbonBanking(data) {
    return request({
        url: '/api/v1/Transaction/carbonBanking',
        method: 'post',
        data,
        islogin: true
    })
}


export function feedBack(data) {
    return request({
        url: '/api/v1/User/feedBack',
        method: 'post',
        data,
        islogin: true
    })
}


export function editInfo(data) {
    return request({
        url: '/api/v1/User/editInfo',
        method: 'post',
        data,
        islogin: true
    })
}


export function modifyPhone(data) {
    return request({
        url: '/api/v1/User/modifyPhone',
        method: 'post',
        data,
        islogin: true
    })
}


export function forgotPwd(data) {
    return request({
        url: '/api/v1/Login/forgotPwd',
        method: 'post',
        data,
        islogin: false
    })
}


export function projectType(data) {
    return request({
        url: '/api/v1/Common/projectType',
        method: 'get',
        params: data,
        islogin: false
    })
}


export function articleDetail(data) {
    return request({
        url: '/api/v1/Article/articleDetail',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function applyZerocarbon(data) {
    return request({
        url: '/api/v1/ZeroCarbon/applyZerocarbon',
        method: 'post',
        data,
        islogin: true
    })
}

export function userRecharge(data) {
    return request({
        url: '/api/v1/Cash/userRecharge',
        method: 'post',
        data,
        islogin: true
    })
}

export function projectYear(data) {
    return request({
        url: '/api/v1/Finance/projectYear',
        method: 'get',
        params: data,
        islogin: true
    })
}

export function applyWithdrawal(data) {
    return request({
        url: '/api/v1/Withdrawal/applyWithdrawal',
        method: 'post',
        data,
        islogin: true
    })
}

export function sizeType(data) {
    return request({
        url: '/api/v1/Common/sizeType',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function endTrusteeship(data) {
    return request({
        url: '/api/v1/Transaction/endTrusteeship',
        method: 'post',
        data,
        islogin: true
    })
}



export function payOrder(data) {
    return request({
        url: '/api/v1/Order/payOrder',
        method: 'post',
        data,
        islogin: true
    })
}

export function zerocarbonPay(data) {
    return request({
        url: '/api/v1/ZeroCarbon/zerocarbonPay',
        method: 'post',
        data,
        islogin: true
    })
}

export function articleType(data) {
    return request({
        url: '/api/v1/Article/articleType',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function articleList(data) {
    return request({
        url: '/api/v1/Article/articleList',
        method: 'get',
        params: data,
        islogin: false
    })
}


export function cancelTrusteeship(data) {
    return request({
        url: '/api/v1/Transaction/cancelTrusteeship',
        method: 'post',
        data,
        islogin: true
    })
}

export function carbonPrice(data) {
    return request({
        url: '/api/v1/Calculation/carbonPrice',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function addRetirement(data) {
    return request({
        url: '/api/v1/Order/addRetirement',
        method: 'post',
        data,
        islogin: true
    })
}

export function projectHold(data) {
    return request({
        url: '/api/v1/Finance/projectHold',
        method: 'get',
        params: data,
        islogin: true
    })
}


export function carbonRetirement(data) {
    return request({
        url: '/api/v1/Transaction/carbonRetirement',
        method: 'post',
        data,
        islogin: true
    })
}

export function countryList(data) { 
    return request({
        url: '/api/v1/Common/countryList',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function countryArea(data) { 
    return request({
        url: '/api/v1/Common/countryArea',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function sceneDetail(data) { 
    return request({
        url: '/api/v1/Calculation/sceneDetail',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function sceneCalculate(data) { 
    return request({
        url: '/api/v1/Calculation/sceneCalculate',
        method: 'post',
        data,
        islogin: true
    })
}

export function calculationOrder(data) { 
    return request({
        url: '/api/v1/Calculation/calculationOrder',
        method: 'post',
        data,
        islogin: true
    })
}

export function countryNamebyId(data) { 
    return request({
        url: '/api/v1/Common/countryNamebyId',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function payCalculation(data) { 
    return request({
        url: '/api/v1/Calculation/payCalculation',
        method: 'post',
        data,
        islogin: true
    })
}

export function getContent(data) { 
    return request({
        url: '/api/v1/Calculation/getContent',
        method: 'get',
        params: data,
        islogin: false
    })
}

export function userExit(data) { 
    return request({
        url: '/api/v1/Login/userExit',
        method: 'post',
        data,
        islogin: true
    })
}


