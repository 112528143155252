import axios from 'axios'
import router from '../router'
import { MessageBox, Message } from 'element-ui'
import language from '@/language/index'
var lg = language.toggle()
// import store from '@/store'
// import { getToken } from '@/utils/auth'
var languagetype = localStorage.getItem('language');
if (!languagetype) languagetype = 'en-us';
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 100000
})

// 请求拦截
service.interceptors.request.use(
    config => {
        if (config.islogin) {
            config.headers = {
                // 'content-type': 'application/json',
                'device': '3',
                'lang': languagetype,
                'token': localStorage.getItem('token')
            }
        } else {
            config.headers = {
                // 'content-type': 'application/json',
                'device': '3',
                'lang': languagetype
            }
        }


        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code == 200) {
            return res
        } else if (res.code == 100401 || res.code == 100402 || res.code == 100403 || res.code == 100405) {
            Message({
                message: lg.tologin,
                type: 'warning',
                duration: 3000
            })
            localStorage.removeItem('token');
            localStorage.removeItem('userinfo');
            router.push('/login');
            return Promise.reject(error)
        } else {
            Message({
                message: res.msg,
                type: 'error',
                duration: 3000
            })
            return Promise.reject(error)
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service