import axios from 'axios'
import router from '../router'
import { MessageBox, Message } from 'element-ui'
import language from '@/language/index'
var lg = language.toggle()
// import store from '@/store'
// import { getToken } from '@/utils/auth'
var languagetype = localStorage.getItem('language');
if (!languagetype) languagetype = 'en-us';
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_APIT,
    timeout: 100000
})
// 请求拦截
service.interceptors.request.use(
    config => {
        if (config.islogin) {
            config.headers = {
                'Content-Type': config.requesthead,
                'device': '3',
                'lang': languagetype,
                'token': localStorage.getItem('token'),
                'site': '2' // 1 气候商店 2 英文版
            }
        } else {
            config.headers = {
                'Content-Type': config.requesthead,
                'device': '3',
                'lang': languagetype,
                'site': '2' // 1 气候商店 2 英文版
            }
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.success === true) {
            return res
        }
        //  else if (res.code == 100401 || res.code == 100402 || res.code == 100403 || res.code == 100405) {
        //     Message({
        //         message: lg.tologin,
        //         type: 'warning',
        //         duration: 3000
        //     })
        //     localStorage.removeItem('token');
        //     localStorage.removeItem('userinfo');
        //     router.push('/login');
        //     return Promise.reject(error)
        // }
        else {
            Message({
                message: res.errorMessage,
                type: 'error',
                duration: 3000
            })
            return Promise.reject(error)
        }
    },
    error => {
        if (error.response.data.errorMessage === "Login_Fail") {

            Message({
                message: lg.tologin,
                type: 'warning',
                duration: 3000
            })
            localStorage.removeItem('token');
            localStorage.removeItem('userinfo');
            router.push('/login');
            return Promise.reject(error)

        }
        
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service